@font-face {
  font-family: "BebasNeue";
  src: local("BebasNeue"),
    url(./assets/fonts/BebasNeue-1.otf) format("opentype");
}

@font-face {
  font-family: "SourceHanSansCN";
  font-weight: 200;
  src: local("SourceHanSansCN"),
    url(./assets/fonts/SourceHanSansCN-Regular.otf) format("opentype");
}

@font-face {
  font-family: "SukhumvitSet";
  src: local("SukhumvitSet"),
    url(./assets/fonts/SukhumvitSet-Text.otf) format("opentype");
}

@font-face {
  font-family: "ShipporiMincho";
  src: local("ShipporiMincho"),
    url(./assets/fonts/ShipporiMinchoB1-Regular.ttf) format("opentype");
}

@font-face {
  font-family: "gbsn";
  src: local("gbsn"), url(./assets/fonts/gbsn00lp.ttf) format("opentype");
}

body {
  margin: 0;
  font-family: "BebasNeue";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
